import { useEffect, useState } from "react";
import { BASE_URL } from "../../lib/constants";
import Spinner from "../ui/spinner";

const Hero5Component = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = () => {
    let url = `${BASE_URL}/sectionE`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setData(json.sectionE);
        setIsLoading(false);
      });
  };

  useEffect(fetchData, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div id="section-products" className="bg-gray-900">
      <div className="relative isolate pt-14">
        {/* <div */}
        {/*   aria-hidden="true" */}
        {/*   className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" */}
        {/* > */}
        {/*   <div */}
        {/*     style={{ */}
        {/*       clipPath: */}
        {/*         "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)", */}
        {/*     }} */}
        {/*     className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" */}
        {/*   /> */}
        {/* </div> */}
        <div className="py-24 sm:py-32 lg:pb-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
                {data?.[0]?.value} <span className="gradient-text">{data?.[1]?.value}</span>{" "}
                {data?.[2]?.value}
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">{data?.[3]?.value}</p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href={data?.[4]?.href}
                  className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm gradient-button"
                >
                  {data?.[4]?.value}
                </a>
                <a href={data?.[5]?.href} className="text-sm font-semibold leading-6 text-white">
                  {data?.[5]?.value} <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            {data?.[6]?.value && (
              <>
                {/* hide in mobile */}
                <img
                  alt="App screenshot"
                  src={data?.[6]?.value}
                  width={2432}
                  height={1442}
                  className="mt-16 rounded-md sm:mt-24 hidden sm:block"
                />
                {/* hide in desktop */}
                <img
                  alt="App screenshot"
                  src={data?.[7]?.value}
                  width={2432}
                  height={1442}
                  className="mt-16 rounded-md sm:mt-24 block sm:hidden"
                />
              </>
            )}
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero5Component;
