import { useRef, useState } from "react";

import { Device } from "@twilio/voice-sdk";

import { BACKEND_HOST } from "../../lib/constants";

import aiAgent1 from "../../assets/img/ai-sales.png";
import aiAgent2 from "../../assets/img/ai-support.png";
import aiAgent3 from "../../assets/img/ai-retention.png";
import audio1 from "../../assets/audio/AI-Valentina-Demo.mp3";
import audio2 from "../../assets/audio/AI-Emma-Demo.mp3";
import audio3 from "../../assets/audio/AI-Retention-V2.m4a";

const imageType = (type) => {
  let image = null;
  switch (type) {
    case "sales":
      image = aiAgent1;
      break;
    case "support":
      image = aiAgent2;
      break;
    case "retention":
      image = aiAgent3;
      break;
    default:
      image = aiAgent1;
  }
  return image;
};

const audioType = (type) => {
  let image = null;
  switch (type) {
    case "sales":
      image = audio1;
      break;
    case "support":
      image = audio2;
      break;
    case "retention":
      image = audio3;
      break;
    default:
      image = audio1;
  }
  return image;
};

const AIServiceButton = ({ type, phoneNumber }) => {
  const [isCalling, setIsCalling] = useState(false);
  const [device, setDevice] = useState(false);
  const audioRef = useRef(null);

  const getTwilioAccessToken = async () => {
    return (
      await fetch(BACKEND_HOST + "/landing/twilio/demo_access_tokens.json?type=" + type, {
        method: "POST",
      })
    ).json();
  };

  const call = async () => {
    try {
      const { token } = await getTwilioAccessToken();
      if (token) {
        const device = new Device(token, {
          logLevel: 3,
          codecPreferences: ["opus", "pcmu"],
        });
        device.register();
        setDevice(device);
        device.on("registered", async () => {
          setIsCalling(true);

          const params = {
            To: phoneNumber,
          };

          const connection = await device.connect({ params });
          connection.on("disconnect", () => {
            setIsCalling(false);
          });

          connection.on("error", (error) => {
            setIsCalling(false);
          });
        });

        device.on("error", (error) => {
          setIsCalling(false);
        });
      } else {
        playAudio();
      }
    } catch (error) {
      playAudio();
    }
  };

  const endCall = () => {
    if (device) {
      device.disconnectAll();
    }
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <>
      {!isCalling ? (
        <img
          src={imageType(type)}
          style={{ cursor: "pointer" }}
          onClick={phoneNumber ? call : playAudio}
          alt="AI Retention Profile"
          className="h-14 w-auto object-cover mb-4 transform hover:scale-105 transition-transform duration-300 ease-in-out"
        />
      ) : (
        <img
          src={imageType(type)}
          style={{ cursor: "pointer" }}
          onClick={endCall}
          alt="AI Retention Profile"
          className="h-14 w-auto object-cover mb-4 transform hover:scale-105 transition-transform duration-300 ease-in-out"
        />
      )}
      <audio ref={audioRef} src={audioType(type)}></audio>
    </>
  );
};

export default AIServiceButton;
