import ycLogo from "../../assets/img/yc.svg";

const Banner = ({ bgColor = "" }) => {
  return (
    <div
      className="mt-0 isolate overflow-hidden"
      style={{ backgroundColor: bgColor ? bgColor : "#f357251a" }}
    >
      <div className="mx-auto max-w-7xl py-4 sm:pt-24 lg:pt-4 relative isolate flex items-center gap-x-6 sm:px-3.5 sm:before:flex-1 hidden md:block">
        <div className="flex w-full items-center justify-between gap-x-4 gap-y-2">
          <img width="143" alt="yc logo" src={ycLogo} />
          <p
            className="text-sm leading-6 text-gray-900"
            style={{ color: "#f35725" }}
          >
            We are participating in <span className="font-bold">YC S24</span>!
          </p>
          <a
            href="mailto:founders@seals.ai"
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            style={{ backgroundColor: "#f35725" }}
          >
            Investor? Email us <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
      <div className="mx-auto max-w-7xl pt-4 pb-2 sm:pt-24 lg:pt-4 relative isolate flex items-center gap-x-6 sm:px-3.5 sm:before:flex-1 block md:hidden">
        <div className="flex mx-auto items-center justify-between gap-x-4 gap-y-2">
          <p className="text-md text-gray-900" style={{ color: "#f35725" }}>
            We are participating in <span className="font-bold">YC S24</span>!
          </p>
        </div>
      </div>
      <div className="mb-4 w-full block md:hidden grid grid-cols-1 place-content-center">
        <a
          href="mailto:founders@hireseals.ai"
          className="rounded-full px-3.5 py-1 text-sm font-semibold text-white shadow-sm mx-auto"
          style={{ backgroundColor: "#f35725" }}
        >
          Investor? Email us <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  );
};

export default Banner;
