import { CheckCircleIcon } from "@heroicons/react/20/solid";

const Alert = ({ classes = "" }) => {
  return (
    <div className={`rounded-md bg-green-50 p-2 my-5 ${classes}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            aria-hidden="true"
            className="h-5 w-5 text-green-400"
          />
        </div>
        <div className="ml-3">
          <div className="text-sm text-green-700">
            <p>message sent.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
