export const BASE_URL = "https://api.sheety.co/7b9b9ddd557c0acab06166f990ab31f1/langinPage";

export const SHEETS_URL =
  "https://script.google.com/macros/s/AKfycbwqIJa-aR_fjxkIAjK9ROOagZ7lmQD20vf5Hf7Gj0nYrcMVlsRtuPVFx88zl3CJW_MT0w/exec";

export const SHEETY_URL =
  "https://api.sheety.co/7b9b9ddd557c0acab06166f990ab31f1/landingLeads/responses";

export const PRIMARY_COLOR = "#EA3C25";

export const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
export const DEMO_SALES_PHONE_NUMBER = process.env.REACT_APP_DEMO_SALES_PHONE_NUMBER;
export const DEMO_SUPPORT_PHONE_NUMBER = process.env.REACT_APP_DEMO_SUPPORT_PHONE_NUMBER;
export const DEMO_RETENTION_PHONE_NUMBER = process.env.REACT_APP_DEMO_RETENTION_PHONE_NUMBER;
