import { useEffect, useState } from "react";
import { BASE_URL } from "../../lib/constants";
import Spinner from "../ui/spinner";
// import {
//   ArrowPathIcon,
//   CloudArrowUpIcon,
//   FingerPrintIcon,
//   LockClosedIcon,
// } from "@heroicons/react/24/outline";

// const getIcon = (icon) => {
//   let CustomIcon = null;
//   switch (icon) {
//     case "LockClosedIcon":
//       CustomIcon = LockClosedIcon;
//       break;
//     case "ArrowPathIcon":
//       CustomIcon = ArrowPathIcon;
//       break;
//     case "FingerPrintIcon":
//       CustomIcon = FingerPrintIcon;
//       break;
//     default:
//       CustomIcon = CloudArrowUpIcon;
//       break;
//   }
//   return (
//     <CustomIcon
//       aria-hidden="true"
//       className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
//     />
//   );
// };

const Feature4Component = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = () => {
    let url = `${BASE_URL}/sectionIid`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setData(json.sectionIid);
        setIsLoading(false);
      });
  };

  useEffect(fetchData, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="overflow-hidden bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 gradient-text">
                {data?.[1]?.value}
              </h2>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {data?.[2]?.value}
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">{data?.[3]?.value}</p>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {data?.[4]?.value}
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">{data?.[5]?.value}</p>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {data?.[6]?.value}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">{data?.[7]?.value}</p>
            </div>
          </div>
          {/* hide in mobile */}
          <div className="flex items-start justify-end lg:order-first hidden sm:block">
            {data?.length > 0 && (
              <img
                alt="Product screenshot"
                src={data?.[0]?.value}
                className="w-[30rem] max-w-none rounded-xl sm:w-[35rem]"
              />
            )}
          </div>
          {/* hide in desktop */}
          <div className="flex block sm:hidden">
            {data?.length > 0 && (
              <img
                alt="Product screenshot"
                src={data?.[0]?.value}
                className="w-[20rem] rounded-xl"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature4Component;
