import Hero2Component from "./hero2.component";
import Hero3Component from "./hero3.component";
import Hero5Component from "./hero5.component";
import Feature3Component from "./feature3.component";
import Feature4Component from "./feature4.component";
import LogosComponent from "./logos.component";
import CTAComponent from "./cta.component";
import Cookies from "../ui/cookies";
import Banner from "../ui/banner";

const HomeComponent = () => {
  return (
    <>
      <Cookies />
      <Banner />
      {/* Hero 2 section */}
      <Hero2Component />

      {/* Hero 5 section */}
      <Hero5Component />

      {/* Feature section */}
      <Feature3Component />

      {/* Feature section */}
      <Feature4Component />

      {/* Hero 3 section */}
      <Hero3Component />

      {/* Logo cloud */}
      <LogosComponent />

      {/* CTA section */}
      <CTAComponent />
    </>
  );
};

export default HomeComponent;
