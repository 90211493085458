import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { BASE_URL, SHEETS_URL } from "../../lib/constants";
import Spinner from "../ui/spinner";
import Alert from "../ui/alert";

const ContactComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    how_can_help: "",
    annual_revenue: "",
    employees: "",
  });

  const fetchData = () => {
    let url = `${BASE_URL}/sectionContact`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setData(json.sectionContact);
        setIsLoading(false);
      });
  };

  useEffect(fetchData, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clearForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      how_can_help: "",
      annual_revenue: "",
      employees: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const response = await fetch(SHEETS_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        mode: "no-cors",
      });

      if (response.ok) {
        console.log("Datos enviados exitosamente");
        setShowAlert(true);
      } else {
        setShowAlert(false);
        // TODO There was an error, please try again later.
        console.error(response);
        console.error("Error al enviar los datos");
      }
      setIsSubmitting(false);
      clearForm();
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error al enviar los datos:", error);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-18 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <Alert classes={showAlert ? "block" : "hidden"} />
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {data?.[0]?.value}
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          {data?.[1]?.value}
        </p>
      </div>
      <form onSubmit={handleSubmit} className="mt-32 mx-auto max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label
              htmlFor="first_name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              First name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Last name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                autoComplete="last_name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="company"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Company
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <div className="flex justify-between text-sm leading-6">
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                How can we help you?
              </label>
              <p id="message-description" className="text-gray-400">
                Max 500 characters
              </p>
            </div>
            <div className="mt-2.5">
              <input
                id="how_can_help"
                name="how_can_help"
                type="text"
                value={formData.how_can_help}
                onChange={handleChange}
                aria-describedby="message-description"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={""}
                required
              />
            </div>
          </div>
          <fieldset className="sm:col-span-2">
            <div>
              <label
                htmlFor="Annual Revenue"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Annual Revenue
              </label>
              <select
                id="annual_revenue"
                name="annual_revenue"
                value={formData.annual_revenue}
                onChange={handleChange}
                className="mt-2 block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              >
                <option value=""></option>
                <option value="0 - 5M USD">{`< $5M USD`}</option>
                <option value="5M - 20M USD">$5M - $20M USD</option>
                <option value="20M - 50M USD">$20M - $50M USD</option>
                <option value="100M - 500M USD">$100M - $500M USD</option>
              </select>
            </div>
          </fieldset>
          <fieldset className="sm:col-span-2">
            <div>
              <label
                htmlFor="Employees"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Employees
              </label>
              <select
                id="employees"
                name="employees"
                value={formData.employees}
                onChange={handleChange}
                className="mt-2 block w-full rounded-md border-0 py-2.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              >
                <option></option>
                <option value="0 - 10">{`0 - 10`}</option>
                <option value="11- 25">11- 25</option>
                <option value="26 - 50">26 - 50</option>
                <option value="51 - 200">51 - 200</option>
                <option value="201 - 1000">201 - 1,000</option>
                <option value="1001 or more">1,001 or more</option>
              </select>
            </div>
          </fieldset>
        </div>
        <div className="mt-10 flex justify-end border-t border-gray-900/10 pt-8">
          <button
            type="submit"
            className="flex rounded-md px-8 py-2.5 text-center text-sm font-semibold text-white shadow-sm gradient-button gap-1.5"
          >
            Send message &nbsp;{" "}
            {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactComponent;
