import { useEffect, useState } from "react";
import { BASE_URL } from "../../lib/constants";
import Spinner from "../ui/spinner";
// import {
//   ArrowPathIcon,
//   CloudArrowUpIcon,
//   FingerPrintIcon,
//   LockClosedIcon,
// } from "@heroicons/react/24/outline";

// const getIcon = (icon) => {
//   let CustomIcon = null;
//   switch (icon) {
//     case "LockClosedIcon":
//       CustomIcon = LockClosedIcon;
//       break;
//     case "ArrowPathIcon":
//       CustomIcon = ArrowPathIcon;
//       break;
//     case "FingerPrintIcon":
//       CustomIcon = FingerPrintIcon;
//       break;
//     default:
//       CustomIcon = CloudArrowUpIcon;
//       break;
//   }
//   return (
//     <CustomIcon
//       aria-hidden="true"
//       className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
//     />
//   );
// };

const Feature3Component = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = () => {
    let url = `${BASE_URL}/sectionIic`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setData(json.sectionIic);
        setIsLoading(false);
      });
  };

  useEffect(fetchData, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="overflow-hidden bg-white py-12">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 gradient-text">
                {data?.[1]?.value}
              </h2>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {data?.[2]?.value}
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">{data?.[3]?.value}</p>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {data?.[4]?.value}
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">{data?.[5]?.value}</p>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {data?.[6]?.value}
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">{data?.[7]?.value}</p>
            </div>
          </div>
          {/* hide in mobile */}
          <div className="mx-auto flex max-w-2xl lg:ml-10 lg:mr-0 lg:max-w-none lg:flex-none h-full hidden sm:block">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none my-auto">
              <img
                alt="App screenshot"
                src={data?.[0]?.value}
                className="w-[32rem] rounded-md bg-white/5"
              />
            </div>
          </div>
          {/* hide in desktop */}
          <div className="mx-auto flex block sm:hidden">
            <div className="flex-none my-auto">
              <img
                alt="App screenshot"
                src={data?.[0]?.value}
                className="w-[20rem] rounded-md bg-white/5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature3Component;
