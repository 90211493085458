"use client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import FooterComponent from "./components/common/footer.component";
import ContactComponent from "./components/common/contact.component";
import HomeComponent from "./components/common/home.component";
import PricingComponent from "./components/common/pricing.component";

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div className="bg-white">
        <main className="isolate">
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route path="/pricing" element={<PricingComponent />} />
            <Route path="/contact" element={<ContactComponent />} />
            <Route path="*" element={<HomeComponent />} />
          </Routes>
        </main>

        {/* Footer */}
        <FooterComponent />
      </div>
    </Router>
  );
};

export default App;
