import { useEffect, useState } from "react";
import { BASE_URL } from "../../lib/constants";
import Spinner from "../ui/spinner";

import { CheckIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PricingComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = () => {
    let url = `${BASE_URL}/sectionPricing`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setData(json.sectionPricing);
        setIsLoading(false);
      });
  };

  useEffect(fetchData, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-18 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div>
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 className="text-base font-semibold leading-7 gradient-text">
          {data?.[0]?.value}
        </h2>
        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {data?.[1]?.value}
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
        {data?.[2]?.value}
      </p>
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
        <div
          className={classNames(
            false
              ? "relative bg-gray-900 shadow-2xl"
              : "bg-white/60 sm:mx-8 lg:mx-0",
            false
              ? ""
              : true
              ? "rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none"
              : "sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl",
            "rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
          )}
        >
          <h3
            className={classNames(
              "gradient-text",
              "text-base font-semibold leading-7"
            )}
          >
            {data?.[3]?.value}
          </h3>
          <p className="mt-4 flex items-baseline gap-x-2">
            <span
              className={classNames(
                false ? "text-white" : "text-gray-900",
                "text-5xl font-bold tracking-tight"
              )}
            >
              {data?.[4]?.value}
            </span>
            <span
              className={classNames(
                false ? "text-gray-400" : "text-gray-500",
                "text-base"
              )}
            >
              /week
            </span>
          </p>
          <p
            className={classNames(
              false ? "text-gray-300" : "text-gray-600",
              "mt-6 text-base leading-7"
            )}
          >
            {data?.[5]?.value}
          </p>
          <ul
            className={classNames(
              false ? "text-gray-300" : "text-gray-600",
              "mt-8 space-y-3 text-sm leading-6 sm:mt-10"
            )}
          >
            {data?.[6]?.value?.split(";").map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <CheckIcon
                  aria-hidden="false"
                  className={classNames(
                    false ? "text-indigo-400" : "gradient-icon",
                    "h-6 w-5 flex-none"
                  )}
                />
                {feature}
              </li>
            ))}
          </ul>
          <a
            href={data?.[7]?.href}
            className={classNames(
              false
                ? "bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500"
                : "gradient-button-outline  ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600",
              "mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10"
            )}
          >
            {data?.[7]?.value}
          </a>
        </div>
        <div
          className={classNames(
            true
              ? "relative bg-gray-900 shadow-2xl"
              : "bg-white/60 sm:mx-8 lg:mx-0",
            true
              ? ""
              : false
              ? "rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none"
              : "sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl",
            "rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
          )}
        >
          <h3
            className={classNames(
              "gradient-text",
              "text-base font-semibold leading-7"
            )}
          >
            {data?.[9]?.value}
          </h3>
          <p className="mt-4 flex items-baseline gap-x-2">
            <span
              className={classNames(
                true ? "text-white" : "text-gray-900",
                "text-5xl font-bold tracking-tight"
              )}
            >
              {data?.[10]?.value}
            </span>
            <span
              className={classNames(
                true ? "text-gray-400" : "text-gray-500",
                "text-base"
              )}
            >
              /month
            </span>
          </p>
          <p
            className={classNames(
              true ? "text-gray-300" : "text-gray-600",
              "mt-6 text-base leading-7"
            )}
          >
            {data?.[11]?.value}
          </p>
          <ul
            className={classNames(
              true ? "text-gray-300" : "text-gray-600",
              "mt-8 space-y-3 text-sm leading-6 sm:mt-10"
            )}
          >
            {data?.[12]?.value?.split(";").map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <CheckIcon
                  aria-hidden="true"
                  className={classNames(
                    true ? "gradient-icon" : "text-indigo-600",
                    "h-6 w-5 flex-none"
                  )}
                />
                {feature}
              </li>
            ))}
          </ul>
          <a
            href={data?.[13]?.href}
            className={classNames(
              true
                ? "gradient-button text-white shadow-sm"
                : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600",
              "mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10"
            )}
          >
            {data?.[13]?.value}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
